import WebServiceRequest from '../Api/WebServiceRequest'

class SeminarCreateSeminarRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Seminar/Create')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class SeminarUpdateSeminarRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Seminar/Update')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class SeminarGetRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Seminar/Get?seminarId='+id)
  }
}

class SeminarDeleteRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Seminar/Delete?id='+id)
  }
}

class SeminarGetAllRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Seminar/GetAll')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class SeminarGetPreservedRowsRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Seminar/PreservedRows?seminarId='+id)
  }
}

class SeminarGeneratesignedListBySeminarIdRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setId(id){
    super.setTag('Seminar/GeneratesignedListBySeminarId?seminarId='+id)
  }
}

export {
  SeminarCreateSeminarRequest,
  SeminarUpdateSeminarRequest,
  SeminarGetRequest,
  SeminarDeleteRequest,
  SeminarGetAllRequest,
  SeminarGetPreservedRowsRequest,
  SeminarGeneratesignedListBySeminarIdRequest
}
