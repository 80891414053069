import WebServiceRequest from '../Api/WebServiceRequest'

class ReserveCheckReserveIdsRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Reserve/CheckReserveIds')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class ReserveCreateRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Reserve/Create')
  }
  setParams(data){
    super.setRequestParamDataObj(data)
  }
}

class ReserveGetReserveDataRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Reserve/GetReserveData')
  }
}

class ReserveGetPurchasedSeatBySeminarIdRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Reserve/GetPurchasedSeatBySeminarId')
  }
  setData(data){
    super.setRequestParam(data)
  }
}

class ReserveChangePriceBySeminarRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Reserve/ChangePriceBySeminar')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}

class ReserveChangePriceBySectionRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Reserve/ChangePriceBySection')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}

class ReserveChangePriceByRowRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('Reserve/ChangePriceByRow')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}

class ReserveGetReserveDataByOrderIdRequest extends WebServiceRequest {
  constructor (context) {
    super(context)
  }
  setOrderId(orderId){
    super.setTag('Reserve/GetReserveDataByOrderId?orderId='+orderId)
  }
}

export {
  ReserveCheckReserveIdsRequest,
  ReserveGetReserveDataRequest,
  ReserveGetPurchasedSeatBySeminarIdRequest,
  ReserveChangePriceBySeminarRequest,
  ReserveChangePriceBySectionRequest,
  ReserveChangePriceByRowRequest,
  ReserveCreateRequest,
  ReserveGetReserveDataByOrderIdRequest
}
